import React from "react";
import { HStack, Input } from "native-base";
import { useConnect } from "./connect";

const OtpComponent = ({ otp, setOtp, phoneNumber }) => {
  const { inputRefs, handleInputChange, handleKeyPress } = useConnect({ otp, setOtp, phoneNumber });

  const renderInputBoxes = () => {
    const inputs = [];
    for (let i = 0; i < 4; i++) {
      inputs.push(
        <Input
          variant="login"
          key={i}
          flex={0.25}
          borderRadius="5px"
          style={{ textAlign: "center" }}
          size="2xl"
          onChangeText={(value) => handleInputChange(i, value)}
          onKeyPress={(e) => handleKeyPress(e, i)}
          maxLength={1}
          keyboardType="numeric"
          ref={(ref) => (inputRefs.current[i] = ref)}
          testID="otpInput"
        />,
      );
    }
    return inputs;
  };

  return <HStack space={4}>{renderInputBoxes()}</HStack>;
};

export default OtpComponent;
