const colors = {
  transparent: "transparent",
  black: "#454545",
  whatsapp: "#1eb88a",
  primary: {
    carnation: "#F56B57",
    darkCarnation: "#A14335",
    softCarnation: "#FEF0EE",
  },
  secondary: {
    softOrchid: "#EBEFFE",
    orchid: "#3863F0",
    darkOrchid: "#223B90",
    orchidBlue: "#1851EE",
    daybreak: "#40A9FF",
    daybreakLight: "#e9f6fe",
  },
  success: {
    softCactus: "#E6EFE3",
    cactus: "#56893E",
    darkCactus: "#2E6930",
    polarGreen1: "#F6FFED",
    polarGreen3: "#B7EB8F",
  },
  warning: {
    softSunflower: "#FEF0DC",
    sunflower: "#F8B24F",
    darkSunflower: "#A17230",
    gold: "#FFFBE6",
  },
  error: {
    softRose: "#FEEAE8",
    rose: "#F23015",
    darkRose: "#911D0D",
    dustRed1: "#FFF1F0",
    dustRed: "#FFA39E",
  },
  neutral: {
    cotton: "#FFFFFF",
    mist: "#F4F4F4",
    darkMist: "#909090",
    charcoal: "#454545",
    background: "#f2f2f2",
  },
  variants: {
    softClover: "#D5F2EA",
    clover: "#2CBE96",
    darkClover: "#1A725A",
  },
  disabled: {
    smoke: "#C9C9C9",
    smoke50: "rgba(201,201,201, 0.5)",
  },
  tooltip: {
    blueBody: "#E6F7FF",
    blueBorder: "#91D5FF",
  },
};

export default colors;
