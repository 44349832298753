const tenantConfig = {
  in: {
    lang: "en",
    countryCode: "62",
    countryShortName: "IN",
    countryLongName: "INDIA",
    currencyName: "Rupees",
    currencyShortName: "INR",
    currencySymbol: "₹",
    numberFormatting: "en-IN",
    dateLocale: "dayjs/locale/en",
    currencySeperatorRegEx: "\\,",
  },
  id: {
    lang: "id",
    countryCode: "62",
    countryShortName: "ID",
    countryLongName: "INDONESIA",
    currencyName: "Rupiah",
    currencyShortName: "IDR",
    currencySymbol: "Rp",
    numberFormatting: "id-ID",
    dateLocale: "dayjs/locale/id",
    currencySeperatorRegEx: "\\.",
  },
};

export default tenantConfig;
