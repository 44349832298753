import config from "config";
import apiConfig from "./apiConfig";
import invokeApi from "./network";

/**
 *
 * @param {object} payload contains otpType and mobileNumber
 * @returns api promise to be resolved by react query
 */
export const apiLogin = (payload) => {
  const { otpType, mobileNumber } = payload;
  return invokeApi(
    apiConfig.login,
    config.API_URL,
    {
      queryProps: {
        otpType,
        mobileNumber,
      },
    },
    null,
    null,
    null,
  );
};

/**
 *
 * @param {object} payload
 * @param {string} token
 * @returns api promise to be resolved by react query
 */
export const apiOTP = (payload) => {
  return invokeApi(apiConfig.otp, config.API_URL, null, { "x-otp-token": payload.token }, payload, null);
};

/**
 *
 * @returns api promise to be resolved by react query
 */
export const apiLogout = () => {
  return invokeApi(apiConfig.logout, config.API_URL, null, null, null, null);
};

export const apiMe = () => {
  return invokeApi(apiConfig.me, config.API_URL, null, null, null, null);
};

export const apiEmail = () => {
  return invokeApi(apiConfig.email, config.API_URL, null, null, null, null);
};

export const apiEmployeeGroup = () => {
  return invokeApi(apiConfig.employeeGroup, config.API_URL, null, null, null, null);
};

export const apiSummary = () => {
  return invokeApi(apiConfig.summary, config.API_URL, null, null, null, null);
};

export const apiEmployees = (payload) => {
  return invokeApi(
    apiConfig.employees,
    config.API_URL,
    {
      queryProps: payload || null,
    },
    null,
    null,
    null,
  );
};

export const apiInProgress = (payload) => {
  return invokeApi(
    apiConfig.inProgress,
    config.API_URL,
    {
      queryProps: payload || null,
    },
    null,
    null,
    null,
  );
};

export const apiUpdateStatus = (data) => {
  return invokeApi(apiConfig.status, config.API_URL, null, null, data, null);
};

export const apiExport = (data) => {
  return invokeApi(apiConfig.export, config.API_URL, null, null, data, null);
};

export const apiProgressExport = (payload, data) => {
  return invokeApi(
    apiConfig.exportEmployeeRequests,
    config.API_URL,
    {
      queryProps: payload || null,
    },
    null,
    data,
    null,
  );
};

export const apiSingleCreate = (data) => {
  return invokeApi(apiConfig.singleCreate, config.API_URL, null, null, data, null);
};

export const apiPaymentCycles = (payload) => {
  const { limit, skip } = payload;
  return invokeApi(
    apiConfig.paymentCycles,
    config.API_URL,
    {
      queryProps: {
        limit,
        skip,
      },
    },
    null,
    null,
    null,
  );
};

export const apiMarketingBanners = () => {
  return invokeApi(apiConfig.marketingBanner, config.API_URL, null, null, null, null);
};

export const apiBillPayment = (payload) => {
  const { accountTypeId } = payload;
  return invokeApi(apiConfig.billPayment, config.API_URL, { queryProps: { accountTypeId } }, null, null, null);
};

export const apiSignUp = (payload) => {
  return invokeApi(apiConfig.signup, config.API_URL, null, null, payload, null);
};

export const apiTopProducts = () => {
  return invokeApi(apiConfig.topProducts, config.API_URL, null, null, null, null);
};

export const apiToGetTransactions = (payload) => {
  return invokeApi(
    apiConfig.getTransactions,
    config.API_URL,
    {
      queryProps: payload,
    },
    null,
    null,
    null,
  );
};

export const apiToGetBanksList = () => {
  return invokeApi(apiConfig.getBanksList, config.API_URL, null, null, null, null);
};

export const apiToGetEmployerConfig = () => {
  return invokeApi(apiConfig.getEmployerConfig, config.API_URL, null, null, null, null);
};

export const apiToExportTransactionsList = (payload, data) => {
  return invokeApi(
    apiConfig.exportTransactionList,
    config.API_URL,
    {
      queryProps: payload,
    },
    null,
    data,
    null,
  );
};

export const apiSingleEmployeeData = (employeeId) => {
  return invokeApi(
    apiConfig.getEmployeeData,
    config.API_URL,
    {
      queryProps: { employeeId },
    },
    null,
    null,
    null,
  );
};

export const fetchEmployeeRequestById = (employeeId) => {
  return invokeApi(
    apiConfig.getEmployeeRequestById,
    config.API_URL,
    {
      queryProps: { employeeId },
    },
    null,
    null,
    null,
  );
};

export const apiSingleUpdate = (data) => {
  return invokeApi(apiConfig.singleUpdate, config.API_URL, null, null, data, null);
};

export const bulkEmployeesCreate = (data) => {
  return invokeApi(
    apiConfig.bulkCreate,
    config.API_URL,
    null,
    {
      "Content-Type": "multipart/form-data",
    },
    data,
    null,
  );
};

export const sdSchedulingFile = (data) => {
  return invokeApi(
    apiConfig.sdSchedulingFileUpload,
    config.API_URL,
    null,
    {
      "Content-Type": "multipart/form-data",
    },
    data,
    null,
  );
};

export const fetchSdRequestList = ({ requestId, params }) => {
  if (!requestId) return () => {};
  return invokeApi(
    apiConfig.getSdRequestList,
    config.API_URL,
    {
      mixedProps: {
        requestId,
      },
      queryProps: {
        ...params,
      },
    },
    null,
    null,
    null,
  );
};

export const apiSubmitScheduling = (data) => {
  return invokeApi(apiConfig.submitScheduling, config.API_URL, null, null, data, null);
};

export const apiWipeRecordsScheduled = (data) => {
  return invokeApi(apiConfig.wipeRecordsScheduled, config.API_URL, null, null, data, null);
};

export const fetchSdRequests = (payload) => {
  return invokeApi(
    apiConfig.getSdRequests,
    config.API_URL,
    {
      queryProps: payload || null,
    },
    null,
    null,
    null,
  );
};

export const fetchSdRequestDetails = ({ requestId, payload }) => {
  if (!requestId) return () => {};
  return invokeApi(
    apiConfig.getSdRequestDetails,
    config.API_URL,
    {
      mixedProps: {
        requestId,
      },
      queryProps: {
        ...payload,
      },
    },
    null,
    null,
    null,
  );
};

export const apiDownloadAllSDRequests = (data) => {
  return invokeApi(apiConfig.downloadAllSDRequests, config.API_URL, null, null, data, null);
};

export const apiDownloadSdRequest = (payload, requestId, data) => {
  return invokeApi(
    apiConfig.downloadSdRequest,
    config.API_URL,
    {
      queryProps: { ...payload },
      mixedProps: { requestId },
    },
    null,
    data,
    null,
  );
};

export const fetchCategories = (payload) => {
  return invokeApi(
    apiConfig.getCategories,
    config.API_URL,
    {
      queryProps: { ...payload },
    },
    null,
    null,
    null,
  );
};

export const fetchGroupsAndPayDates = () => {
  return invokeApi(apiConfig.getGroupsAndPayDates, config.API_URL, null, null, null, null);
};

export const fetchGroupPaydates = (payload) => {
  return invokeApi(
    apiConfig.fetchGroupPayDates,
    config.API_URL,
    {
      queryProps: payload,
    },
    null,
    null,
    null,
  );
};

export const fetchMeApi = () => {
  return invokeApi(apiConfig.fetchMeApiData, config.API_URL, null, null, null, null);
};

export const fetchFileNamesData = (payload) => {
  return invokeApi(
    apiConfig.fileNamesApi,
    config.API_URL,
    {
      queryProps: payload,
    },
    null,
    null,
    null,
  );
};

export const fetchPICUsersAPI = () => {
  return invokeApi(apiConfig.getPICUsers, config.API_URL, null, null, null, null);
};

export const createPICUsersAPI = (payload) => {
  return invokeApi(apiConfig.createPICUser, config.API_URL, null, null, payload, null);
};

export const editPICUsersAPI = (payload, id) => {
  return invokeApi(apiConfig.editPICUser, config.API_URL, { queryProps: { picUserId: id } }, null, payload, null);
};

export const deletePICUser = (payload) => {
  return invokeApi(
    apiConfig.deletePICUser,
    config.API_URL,
    {
      queryProps: { picUserId: payload },
    },
    null,
    null,
    null,
  );
};

export const fetchInvoiceApi = (payload) => {
  return invokeApi(
    apiConfig.filterInvoiceApi,
    config.API_URL,
    {
      queryProps: payload,
    },
    null,
    null,
    null,
  );
};

export const getAccessTypes = () => invokeApi(apiConfig.getAllAccessTypes, config.API_URL, null, null, null, null);
