import { Text, VStack, Button, FlatList } from "native-base";
import colors from "theme/colors";
import RouteButton from "atoms/RouteButton";
import routeData, { iobRoute } from "global/routes";
import { AiOutlineArrowRight } from "react-icons/ai";
import { getStore } from "store/storeUtils";
import { getFilteredRouteData, LogoutButton } from "molecules/NavBar";
import styles from "./styles";

const MobileNavBar = ({ setDrawerOpen, drawerOpen }) => {
  const { employerConfigData } = getStore();

  const filteredRouteData = getFilteredRouteData(routeData, employerConfigData, iobRoute);

  if (drawerOpen) {
    return (
      <VStack minW="268px" style={styles.sideBar}>
        <VStack space={4} w="90%">
          <Button variant="rounded" bg={colors.variants.clover} w="30px" h="30px" onPress={() => setDrawerOpen(false)}>
            <Text variant="xs" color={colors.neutral.cotton}>
              <AiOutlineArrowRight size="17px" style={{ marginTop: "4px" }} />
            </Text>
          </Button>
          <FlatList data={filteredRouteData} renderItem={(item) => <RouteButton route={item.item} />} />
        </VStack>
        <VStack space={4} w="90%">
          <LogoutButton />
        </VStack>
      </VStack>
    );
  }
  return null;
};

export default MobileNavBar;
