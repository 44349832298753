import { useEffect, useState } from "react";
import { getStore } from "store/storeUtils";
import { validationCheck } from "utils/validation";
import { useMutation } from "@tanstack/react-query";
import { apiLogin } from "api/apiCall";
import { useTranslation } from "react-i18next";
import AmplitudeHelper from "utils/analytics";
import { SentryHelper } from "utils/performance/SentryHelper";

export const useConnect = () => {
  // Get and store phone number
  const { t } = useTranslation("common");
  const [phoneNumber, setPhoneNumber] = useState("");
  const initialErrorState = {
    isError: false,
    errorMessage: "",
  };
  const [phoneError, setPhoneError] = useState(initialErrorState);
  const { loginData, setLoginData } = getStore();

  useEffect(() => {
    AmplitudeHelper.logEvent("login_page");
  }, []);

  const handlePhoneChange = (e) => {
    if (e.target.value == "") {
      setPhoneError({ isError: false, errorMessage: "" });
    }
    AmplitudeHelper.logEvent("phone_number_filled");
    setPhoneNumber(e.target.value);
    if (phoneError.isError) setPhoneError(initialErrorState);
  };

  // API Call & Validation
  const {
    isLoading,
    mutate: performLogin,
    isSuccess,
    data: LoginData,
    isError: isLoginError,
    error: LoginError,
  } = useMutation({
    mutationFn: (payload) => apiLogin({ otpType: payload, mobileNumber: phoneNumber }),
  });

  useEffect(() => {
    if (isSuccess) {
      AmplitudeHelper.logEvent("otp_success", { phone_number: phoneNumber });
      const newLoginData = {
        ...loginData,
        phoneNumber,
        loginSuccess: true,
        token: LoginData?.token,
      };
      setLoginData(newLoginData);
    }
  }, [isSuccess, LoginData]);

  useEffect(() => {
    if (isLoginError) {
      AmplitudeHelper.logEvent("otp_failed", {
        phone_number: phoneNumber,
        failure_message: t(`errorCodes.${LoginError}`),
        source: "login_page",
      });
      setPhoneError({
        isError: true,
        errorMessage: t(`errorCodes.${LoginError}`),
      });
    }
  }, [isLoginError, LoginError]);

  const handleSubmit = (otpType) => {
    try {
      if (phoneNumber.charAt(0) == "8") setPhoneNumber(`62${phoneNumber}`);
      const validation = validationCheck.phone(phoneNumber);
      if (validation != "") {
        const err = {
          isError: true,
          errorMessage: t(`errorCodes.${validation}`),
        };
        setPhoneError(err);
      } else {
        const err = { isError: false, errorMessage: "" };
        setPhoneError(err);
        // call the api
        const newLoginData = {
          ...loginData,
          otpType,
        };
        setLoginData(newLoginData);
        AmplitudeHelper.logEvent("otp_requested", {
          phone_number: phoneNumber,
          source: otpType,
        });
        performLogin(otpType);
      }
    } catch (error) {
      SentryHelper.breadCrumbHepler(error);
    }
  };

  return {
    phoneNumber,
    handlePhoneChange,
    phoneError,
    handleSubmit,
    performLogin,
    isLoading,
  };
};
