import dayjs from "dayjs";
import { CREATE_MODALS, EMPLOYER_STATUS_TYPE } from "global/constants";
import { fieldNames } from "global/fieldNameMapping";
import AmplitudeHelper from "utils/analytics";
import { showErrorToast } from "utils/utilities";
import { validationCheck } from "utils/validation";

export function checkOnSuccess(createSuccess, showCreateUpdateSuccessToast, createEmployeeResponse, navigate, toast) {
  if (createSuccess) {
    showCreateUpdateSuccessToast({
      createEmployeeResponse,
      navigate,
      toast,
      isCreate: true,
    });
  }
}

export function checkErrorsOnCreate(isCreateError, createError, t, setErrors, toast, navigate, resetLoginData) {
  if (isCreateError && createError?.length > 0) {
    const newErrors = {};

    createError.forEach((errorCode) => {
      const field = fieldNames[errorCode]; // map field name to particular error code and select out that one field which corresponds to the error code
      if (field) {
        newErrors[`${field}`] = t(`errorCodes.${errorCode}`);
      }
    });

    if (Object.keys(newErrors).length != 0) {
      setErrors(newErrors);
    } else {
      showErrorToast({ error: createError, toast, navigate, resetLoginData });
    }
  }
}

export function validatorOnSubmit(values, setErrors, setFormData, setModal, modal) {
  const newErrors = {};
  const required = [
    "name",
    "phone",
    "birthDate",
    "ktp",
    "employeeId",
    // "group", removed from Mandatory fields discussed with Desy and team
    "bankNumber",
    "bankName",
    "beneficiaryName",
    "employeeType",
    "status",
    "salary",
    "payDate",
    "gender",
    "dateJoined",
  ];
  const inputs = [
    // "name",
    "phone",
    // "ktp",
    "birthDate",
    "dateJoined",
    // 'employeeId',
    // "bankNumber",
    // "beneficiaryName",
    // "salary",
  ];

  required.forEach((field) => {
    const fieldValue = values[field];
    if (!fieldValue || fieldValue.toString().trim() == "") {
      newErrors[`${field}`] = "basicValidation.missingFields";
    }
  });

  inputs.forEach((field) => {
    let validation = "";
    if (field === "birthDate" || field === "dateJoined") {
      validation = validationCheck[`${field}`](values[field]);
    } else {
      validation = validationCheck[`${field}`](values[`${field}`]);
    }
    if (validation != "" && newErrors[`${field}`] != "basicValidation.missingFields") {
      newErrors[`${field}`] = validation;
    }
  });

  if (Object.keys(newErrors).length != 0) {
    setErrors(newErrors);
  } else {
    setErrors({});
    setFormData(values);
    AmplitudeHelper.logEvent("save_button_clicked", {
      source: "employee_single_creation_page",
    });

    setModal({
      ...modal,
      type: CREATE_MODALS.singleCreate,
      data: { ...values },
    });
  }
}

export function getSuspendedReason(data) {
  return data.suspendedReason !== "" && data.suspensionCategory.includes("Other") ? data.suspendedReason : null;
}

export function getSuspensionCategory(data) {
  return data.suspensionCategory !== "" ? data.suspensionCategory : null;
}

export function getEmployeeStatus(data) {
  return data?.status === "Aktif" || data?.status === "active" ? "active" : "withdraw_suspended";
}

export function populateStatusonLoad(employerSuspended) {
  if (employerSuspended) return EMPLOYER_STATUS_TYPE.WITHDRAW_SUSPENDED;
  return "";
}

export function getGroupData(data, t) {
  return data?.group === t("employeeCreation.single.noGroup") ? "" : data?.group;
}

export function getGroupName(data, t) {
  return data?.group ? getGroupData(data, t) : "";
}

export function getBirthDate(data) {
  return data?.birthDate ? dayjs(data.birthDate).format("YYYY-MM-DD") : undefined;
}

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}

export const formatUnderScoreLowerCase = (keyWord) => {
  if (!keyWord || keyWord.length === 0) {
    return keyWord;
  }
  return keyWord?.toLowerCase()?.replace(/ /g, "_") ?? "";
};

export function showComponents(employerConfigData, route) {
  if (employerConfigData?.isInitialOnboarding) return route?.isInitialOnboarding;
  return route?.isMainFlow;
}
