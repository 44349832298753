export const fieldNames = {
  "GG-VAL-49": "bankName",
  "GG-VAL-50": "bankNumber",
  "GG-VAL-51": "beneficiaryName",
  "GG-VAL-52": "phone",
  "GG-VAL-53": "employeeId",
  "GG-VAL-25": "name",
  "GG-VAL-26": "name",
  "GG-VAL-29": "phone",
  "GG-VAL-21": "employeeId",
  "GG-VAL-30": "gender",
  "GG-VAL-31": "ktp",
  "GG-VAL-32": "dateJoined",
  "GG-VAL-33": "group",
  // "GG-VAL-34": "departmentName",
  // "GG-VAL-35": "designationName",
  "GG-VAL-37": "salary",
  "GG-VAL-28": "birthDate",
  "GG-VAL-38": "bankName",
  "GG-VAL-39": "bankNumber",
  "GG-VAL-40": "beneficiaryName",
  // "GG-VAL-27": "requestType",
  "GG-VAL-04": "payDate",
};
