import { extendTheme } from "native-base";
import colors from "./colors";

const breakpoints = {
  base: 0,
  sm: 480,
  md: 768,
  lg: 992,
  xl: 1280,
};
export const xxxl = {
  fontSize: {
    base: 20,
    sm: 32,
    md: 33,
    lg: 34,
    xl: 36,
  },
};

export const xxl = {
  fontSize: {
    base: 20,
    sm: 32,
    md: 33,
    lg: 34,
    xl: 34,
  },
};

export const xl = {
  fontSize: {
    base: 25,
    sm: 26,
    md: 27,
    lg: 28,
    xl: 28,
  },
};

export const lg = {
  fontSize: {
    base: 19,
    sm: 20,
    md: 21,
    lg: 22,
    xl: 22,
  },
};

export const md = {
  fontSize: {
    base: 17,
    sm: 18,
    md: 19,
    lg: 20,
    xl: 20,
  },
};

export const sm = {
  fontSize: {
    base: 13,
    sm: 15,
    md: 16,
    lg: 17,
    xl: 17,
  },
};

export const xs = {
  fontSize: {
    base: 12,
    sm: 13,
    md: 14,
    lg: 14,
    xl: 14,
  },
};

export const xxs = {
  fontSize: {
    base: 10,
    sm: 10,
    md: 12,
    lg: 12,
    xl: 12,
  },
};

const regularFont = "Nunito";
const boldFont = "Nunito-Bold";

export const theme = extendTheme({
  colors,
  breakpoints,
  fontConfig: {
    Nunito: {
      100: {
        normal: regularFont,
      },
      200: {
        normal: boldFont,
      },
    },
  },
  fonts: {
    heading: "Nunito",
    body: "Nunito",
    mono: "Nunito",
  },
  components: {
    Text: {
      variants: {
        /* NORMAL VARIANTS */
        xxxl: {
          ...xxxl,
          fontFamily: regularFont,
        },
        xxl: {
          ...xxl,
          fontFamily: regularFont,
        },
        xl: {
          ...xl,
          fontFamily: regularFont,
        },
        lg: {
          ...lg,
          fontFamily: regularFont,
        },
        md: {
          ...md,
          fontFamily: regularFont,
        },
        sm: {
          ...sm,
          fontFamily: regularFont,
        },
        xs: {
          ...xs,
          fontFamily: regularFont,
        },
        xxs: {
          ...xxs,
          fontFamily: regularFont,
        },
        /* BOLD VARIANTS */
        "xxxl-bold": {
          ...xxxl,
          fontFamily: boldFont,
        },
        "xxl-bold": {
          ...xxl,
          fontFamily: boldFont,
        },
        "xl-bold": {
          ...xl,
          fontFamily: boldFont,
        },
        "lg-bold": {
          ...lg,
          fontFamily: boldFont,
        },
        "md-bold": {
          ...md,
          fontFamily: boldFont,
        },
        "sm-bold": {
          ...sm,
          fontFamily: boldFont,
        },
        "xs-bold": {
          ...xs,
          fontFamily: boldFont,
        },
        "xxs-bold": {
          ...xxs,
          fontFamily: boldFont,
        },
        disabled: () => ({
          color: colors.neutral.cotton,
        }),
      },
      sizes: {
        xxxl,
        xxl,
        xl,
        lg,
        md,
        sm,
        xs,
      },
    },
    Input: {
      defaultProps: {
        size: "md",
        focusOutlineColor: colors.secondary.orchid,
      },
      variants: {
        login: {
          borderColor: colors.disabled.smoke,
          backgroundColor: colors.neutral.cotton,
          fontFamily: regularFont,
          borderWidth: "1px",
          borderRadius: "0px",
          height: "40px",
          _hover: {
            borderColor: colors.disabled.smoke,
          },
          _focus: {
            borderColor: colors.disabled.smoke,
            bg: colors.neutral.cotton,
            _hover: {
              borderColor: colors.disabled.smoke,
            },
          },
        },
        home: {
          borderColor: colors.disabled.smoke,
          backgroundColor: colors.neutral.cotton,
          fontFamily: regularFont,
          borderWidth: "1px",
          borderRadius: "2px",
          height: "35px",
          _hover: {
            borderColor: colors.secondary.orchid,
          },
          _invalid: {
            borderColor: colors.error.rose,
          },
          _focus: {
            borderColor: colors.disabled.smoke,
            _hover: {
              borderColor: colors.secondary.orchid,
            },
          },
        },
      },
    },
    Button: {
      variants: {
        solid: () => ({
          _disabled: {
            _text: { color: colors.neutral.charcoal },
            backgroundColor: colors.disabled.smoke,
            opacity: 0.9,
          },
          _pressed: { opacity: 0.8 },
          _hover: { bg: colors.secondary.orchid },
          backgroundColor: colors.secondary.orchidBlue,
          borderRadius: "0px",
          opacity: 1,
          height: "35px",
        }),
        login: () => ({
          _disabled: {
            _text: { color: colors.neutral.charcoal },
            backgroundColor: colors.disabled.smoke,
            opacity: 0.9,
          },
          _pressed: { opacity: 0.8 },
          _hover: { bg: colors.secondary.orchid },
          backgroundColor: colors.secondary.orchidBlue,
          borderRadius: "0px",
          opacity: 1,
        }),
        whatsapp: () => ({
          _disabled: {
            _text: { color: colors.neutral.charcoal },
            backgroundColor: colors.disabled.smoke,
            opacity: 0.9,
          },
          _pressed: { opacity: 0.8 },
          _hover: { bg: colors.whatsapp },
          backgroundColor: colors.whatsapp,
          borderRadius: "0px",
          opacity: 1,
          height: "35px",
        }),
        link: {
          _text: {
            color: colors.secondary.orchidBlue,
          },
          fontFamily: boldFont,
          textDecorationLine: "underline",
        },
        outline: {
          height: "35px",
        },
        rounded: {
          _disabled: {
            _text: { color: colors.neutral.charcoal },
            backgroundColor: colors.disabled.smoke,
            opacity: 0.9,
          },
          backgroundColor: colors.secondary.orchid,
          borderRadius: "20px",
          opacity: 1,
          height: "35px",
        },
        ghost: {
          _hover: {
            backgroundColor: colors.neutral.cotton,
          },
        },
      },
    },
    FormControlLabel: {
      variants: {
        login: {
          _text: {
            fontFamily: regularFont,
            fontSize: sm.fontSize,
          },
        },
        team: {
          _text: {
            fontFamily: regularFont,
            fontSize: xs.fontSize,
          },
        },
      },
    },
    FormControlErrorMessage: {
      variants: {
        login: {
          _text: {
            fontFamily: regularFont,
            fontSize: sm.fontSize,
          },
        },
        team: {
          _text: {
            fontFamily: regularFont,
            fontSize: xs.fontSize,
          },
        },
      },
    },
    Checkbox: {
      baseStyle: {
        borderWidth: 1,
        backgroundColor: colors.neutral.cotton,
        _checked: {
          backgroundColor: colors.secondary.orchid,
          borderColor: colors.secondary.orchid,
        },
      },
    },
    Radio: {
      defaultProps: {
        size: "17px",
      },
      baseStyle: {
        borderWidth: 1,
        _checked: {
          borderColor: colors.secondary.orchid,
          _icon: {
            color: colors.secondary.orchid,
          },
        },
      },
    },
    Spinner: {
      defaultProps: {
        color: colors.secondary.orchid,
      },
    },
  },
});
