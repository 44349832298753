import React from "react";
import { Input } from "native-base";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

function CommonPhoneInput(props) {
  const { t } = useTranslation("common");
  const { phoneNumber, handlePhoneChange, onFocusCall } = props;
  return (
    <Input
      variant="login"
      size="xl"
      placeholder={t("login.phonePlaceholder")}
      value={phoneNumber}
      onChange={handlePhoneChange}
      onFocus={onFocusCall}
    />
  );
}

CommonPhoneInput.propTypes = {
  phoneNumber: PropTypes.string,
  handlePhoneChange: PropTypes.func,
  onFocusCall: PropTypes.func,
};
CommonPhoneInput.defaultProps = {
  phoneNumber: "",
  handlePhoneChange: () => {},
  onFocusCall: () => {},
};
export default CommonPhoneInput;
