/* Login Form: Input phone number for auth */

import { Text, Button, Stack, VStack, Image, FormControl } from "native-base";
import { Logo } from "assets";
import { useTranslation } from "react-i18next";
import AmplitudeHelper from "utils/analytics";
import CommonPhoneInput from "atoms/CommonPhoneInput";
import i18next from "i18next";
import colors from "theme/colors";
import config from "config";
import styles from "./styles";
import { useConnect } from "./connect";

function getPhoneErrorMessage(phoneError) {
  if (phoneError.errorMessage) return phoneError.errorMessage;
  return null;
}
export function getChangedLanguage(selectedLanguage) {
  return selectedLanguage === "en" ? "id" : "en";
}

async function changeLanguage() {
  const selectedLanguage = i18next.language;
  const changeLanguageTo = getChangedLanguage(selectedLanguage);
  await i18next.changeLanguage(changeLanguageTo);
  localStorage.setItem("selectedLanguage", changeLanguageTo); // Persist the selected language
}

function shouldShowLanguageChanger(t) {
  return config.SENTRY_ENV === "staging" ? (
    <Button
      variant="solid"
      flex={1}
      testID="btnLanguageChange"
      _text={{
        testID: "Language",
        color: colors.neutral.cotton,
        size: "sm",
        fontWeight: "200",
      }}
      onPress={async () => {
        await changeLanguage();
      }}
    >
      {t("common.language")}
    </Button>
  ) : null;
}

const LoginForm = () => {
  const { t } = useTranslation("common");
  const { phoneNumber, handlePhoneChange, phoneError, handleSubmit, isLoading } = useConnect();

  const buttonArrangement = ["column", "column", "column", "row", "row"];
  const onFocusCall = () => AmplitudeHelper.logEvent("phone_number_clicked");

  return (
    <VStack
      space={styles.breakpoints.containerSpacing}
      alignSelf="left"
      style={styles.container}
      width={styles.breakpoints.containerWidth}
    >
      <VStack space={0}>
        <Image
          height={styles.breakpoints.logoSize}
          width={styles.breakpoints.logoSize}
          resizeMode="contain"
          source={Logo}
          alt="logo"
        />
        <Text variant="xxl-bold" style={styles.welcome}>
          {t("login.welcome")}
        </Text>
      </VStack>
      <FormControl isInvalid={phoneError.isError}>
        <FormControl.Label variant="login">{t("login.phone")}</FormControl.Label>
        <CommonPhoneInput handlePhoneChange={handlePhoneChange} phoneNumber={phoneNumber} onFocusCall={onFocusCall} />
        <FormControl.ErrorMessage variant="login">{getPhoneErrorMessage(phoneError)}</FormControl.ErrorMessage>
      </FormControl>
      <Stack space={3} flex={1} direction={buttonArrangement}>
        <Button
          variant="whatsapp"
          flex={0.5}
          onPress={() => handleSubmit("WHATSAPP")}
          isDisabled={phoneError.isError || phoneNumber.length == 0 || isLoading}
        >
          <Text variant={{ base: "xxs", md: "xs" }} color="white">
            {t("login.whatsapp")}
          </Text>
        </Button>
        <Button
          variant="solid"
          flex={0.5}
          onPress={() => handleSubmit("SMS")}
          isDisabled={phoneError.isError || phoneNumber.length == 0 || isLoading}
        >
          <Text variant={{ base: "xxs", md: "xs" }} color="white">
            {t("login.sms")}
          </Text>
        </Button>
      </Stack>
      {shouldShowLanguageChanger(t)}
    </VStack>
  );
};

export default LoginForm;
