import { useRef, useEffect } from "react";
import AmplitudeHelper from "utils/analytics";

export const useConnect = ({ otp, setOtp, phoneNumber }) => {
  const inputRefs = useRef([]);

  useEffect(() => {
    if (otp.join("").length === 0) {
      inputRefs.current.forEach((input, index) => {
        input.setNativeProps({ text: "" });
        if (index === 0) {
          input.focus();
        }
      });
    }
  }, [otp]); // Ensure otp is correctly used as a dependency

  const handleInputChange = (index, value) => {
    AmplitudeHelper.logEvent("otp_input", { phone_number: phoneNumber });
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < 3) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleBackspace = (index, value) => {
    if (!value && index > 0) {
      const newOtp = [...otp];
      newOtp[index] = "";
      inputRefs.current[index - 1]?.focus();
      setOtp(newOtp);
    }
  };

  const handleKeyPress = (e, index) => {
    if (e.nativeEvent.key === "Backspace" && !otp[index]) {
      handleBackspace(index, otp[index]);
    }
  };

  return { inputRefs, handleInputChange, handleKeyPress };
};
