import { useQuery } from "@tanstack/react-query";
import {
  apiTopProducts,
  apiSummary,
  apiToGetTransactions,
  apiEmployeeGroup,
  apiEmail,
  apiSingleEmployeeData,
  apiToGetBanksList,
  apiToGetEmployerConfig,
  fetchCategories,
  fetchGroupsAndPayDates,
  fetchGroupPaydates,
  fetchMeApi,
  apiInProgress,
  fetchFileNamesData,
  fetchPICUsersAPI,
  fetchInvoiceApi,
  getAccessTypes,
} from "api/apiCall";

export function fetchTopProducts() {
  return useQuery(
    {
      queryKey: ["topProducts_api"],
      queryFn: apiTopProducts,
    },
    {
      enabled: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );
}

export function fetchEmail() {
  return useQuery(
    {
      queryKey: ["email_api"],
      queryFn: apiEmail,
      enabled: true,
    },
    {
      enabled: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );
}

export function fetchEmployeeGroup() {
  return useQuery(
    {
      queryKey: ["employeeGroup_api"],
      queryFn: apiEmployeeGroup,
      enabled: true,
    },
    {
      enabled: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );
}

export function fetchSummary() {
  return useQuery(
    {
      queryKey: ["summary_api"],
      queryFn: apiSummary,
    },
    {
      enabled: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );
}

export function getTransactionData(payload) {
  return useQuery(
    {
      queryKey: ["get_transaction_data"],
      queryFn: () => apiToGetTransactions(payload),
    },
    {
      enabled: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );
}

export function getSingleEmployeeData(payload) {
  return useQuery(
    {
      queryKey: ["get_single_employee_data"],
      queryFn: () => apiSingleEmployeeData(payload),
    },
    {
      enabled: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );
}

export function getBanksList() {
  return useQuery(
    {
      queryKey: ["get_banks_list"],
      queryFn: () => apiToGetBanksList(),
    },
    {
      enabled: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );
}

export function getCategoriesList(payload) {
  return useQuery(
    {
      queryKey: ["get_categories_list"],
      queryFn: () => fetchCategories({ requestType: payload }),
    },
    {
      enabled: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );
}

export function getGroupsAndPayDatesList() {
  return useQuery(
    {
      queryKey: ["get_groups_and_pay_dates_list"],
      queryFn: () => fetchGroupsAndPayDates(),
    },
    {
      enabled: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );
}

export function getEmployerConfig() {
  return useQuery(
    {
      queryKey: ["get_employer_config"],
      queryFn: () => apiToGetEmployerConfig(),
    },
    {
      enabled: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );
}

export function fetchGroupPaydatesQuery(payload) {
  return useQuery(
    {
      queryKey: ["fetch_group_dates", payload],
      queryFn: () => fetchGroupPaydates(payload),
      keepPreviousData: true,
    },
    {
      enabled: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );
}

export function fetchMeApiCall(payload) {
  return useQuery(
    {
      queryKey: ["fetch_me_api", payload],
      queryFn: () => fetchMeApi(payload),
    },
    {
      enabled: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );
}

export function fetchProgressTableData(filterData, currentPage, getPageParams, polling) {
  return useQuery(
    {
      queryKey: ["tableData", filterData, currentPage],
      queryFn: () => apiInProgress(getPageParams()),
      refetchInterval: polling ? 2000 : false,
    },
    {
      enabled: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );
}

export function fetchfileNamesApi(payload) {
  return useQuery(
    {
      queryKey: ["fetchFileNames", payload],
      queryFn: () => fetchFileNamesData(payload),
    },
    {
      enabled: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );
}

export function fetchPICUsers() {
  return useQuery(
    { queryKey: ["picUsers"], queryFn: fetchPICUsersAPI, enabled: false },
    { refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false },
  );
}
export function fetchInvoices(filterData, currentPage, getParams) {
  return useQuery(
    {
      queryKey: ["fetchInvoices", filterData, currentPage],
      queryFn: () => fetchInvoiceApi(getParams()),
    },
    {
      enabled: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: true,
    },
  );
}

export function fetchAllAccessTypes() {
  return useQuery(
    { queryKey: ["fetchAccessTypes"], queryFn: () => getAccessTypes() },
    {
      enabled: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: true,
    },
  );
}
