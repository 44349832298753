export const AMPLITUDE_CONSTANTS = {};

export const AMPLITUDE_EVENTSOURCES = {
  groupAndpaydate_page: "group&paydate_page",
  team_page: "team_page",
  single_employee_creation_clicked: "single_create_button",
  bulk_employee_creation_clicked: "bulk_create_button",
  salary_disbursement_schedule_button: "salary_disbursement_schedule_button",
  single_update: "single_update",
  onboard_employee_page: "onboard_employee_page",
  bulk_employee_creation_page: "bulk_employee_creation_page",
  bulk_onboard_employee_page: "bulk_onboard_employee_page",
  bulk_employee_updation_page: "bulk_employee_updation_page",
  invoices_page: "invoices_page",
};
