import colors from "theme/colors";

const styles = {
  bar: {
    backgroundColor: colors.neutral.cotton,
    width: "100vw",
    height: "8vh",
    paddingVertical: "5px",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "40px",
    paddingRight: "20px",
    boxShadow: "0px 2px 8px 2px rgba(0, 0, 0, 0.10)",
    zIndex: 1,
  },
  text: {
    color: colors.secondary.orchidBlue,
  },
  breakpoints: {
    logoHeight: "38px",
    logoWidth: "50px",
  },
  sideBar: {
    backgroundColor: colors.secondary.orchid,
    alignItems: "center",
    paddingTop: "20px",
    paddingBottom: "15px",
    justifyContent: "space-between",
    height: "100vh",
    width: "200px",
  },
};

export default styles;
