/* 
In App.js:
- Router Container
- Native Base Provider
- React-query provider 
- i18n init
- Amplitude Init
- Sentry Init
*/
import { Root } from "pages";
import {
  BrowserRouter,
  // Routes
} from "react-router-dom";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "api/clientProvider";
import { NativeBaseProvider } from "native-base";
import { en, id } from "i18n/supportedLanguages";
import AmplitudeHelper from "utils/analytics";
import initializePerformanceTool from "utils/performance";
import { theme } from "theme/theme";
import * as Sentry from "@sentry/react";
import { Suspense, useEffect } from "react";
import config from "config";
import tenantConfig from "tenantConfig";
import AppSpinner from "atoms/AppSpinner";

// INITIALIZE SENTRY
initializePerformanceTool();

i18next.init({
  preload: ["id"],
  compatibilityJSON: "v3",
  interpolation: { escapeValue: false }, // React already does escaping
  lng: tenantConfig[config.COUNTRY_CODE].lang, // language to use
  resources: {
    en: {
      common: en,
    },
    id: { common: id },
  },
});

function App() {
  // INITIALIZE AMPLITUDE

  AmplitudeHelper.init();
  useEffect(() => {
    const loadLanguage = async () => {
      const savedLanguage = await localStorage.getItem("selectedLanguage");
      // or
      // const savedLanguage = localStorage.getItem('selectedLanguage'); // For web apps

      if (savedLanguage) {
        await i18next.changeLanguage(savedLanguage);
      }
    };
    loadLanguage();
  }, []);
  // translations init
  return (
    <I18nextProvider i18n={i18next}>
      <NativeBaseProvider theme={theme}>
        <Suspense fallback={<AppSpinner />}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              {/* <SentryRoutes> */}
              <Root />
              {/* </SentryRoutes> */}
            </BrowserRouter>
          </QueryClientProvider>
        </Suspense>
      </NativeBaseProvider>
    </I18nextProvider>
  );
}

export default Sentry.withProfiler(App);
