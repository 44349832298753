import colors from "theme/colors";

const styles = {
  image: {
    height: "6.1rem",
    width: "8rem",
    objectFit: "fill",
  },
  welcome: { color: colors.black },
  container: {
    alignSelf: "flex-start",
    margin: "5%",
    bottom: "10%",
  },
  imageContainer: {
    backgroundColor: "red",
  },
  whatsappButton: {
    backgroundColor: colors.whatsapp,
  },
  breakpoints: {
    containerSpacing: [3, 4, 5, 5, 5],
    containerWidth: ["90%", "90%", "70%", "60%", "60%"],
    logoSize: [50, 50, 75, 100, 100],
  },
  text: { lineHeight: 0 },
  link: {
    color: colors.secondary.orchidBlue,
    textDecorationLine: "underline",
  },
};

export default styles;
